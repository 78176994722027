/*
@contract modal {
  padding: Padding
  width: Width
  height: Height
  panel {
    wrap-padding: Padding
    background: BackgroundColor
    border-radius: BorderRadius
    shadow: BoxShadow
    gap: Gap
    overflow: Overflow
    position: Position
    align-items: AlignItems
    wrap-align-items: AlignItems
    wrap-min-height: MinHeight
    wrap-height: Height
  }
  close-button {
    font-size: FontSize
    padding: Padding
    color: Color
    color-hover: Color
    position: Position
    top: Top
    right: Right
    background: Color
    border-radius: BorderRadius
  }
  container {
    overflow: Overflow
  }
}
*/

.container {
  position: fixed;
  inset: 0;
  overflow-y: var(--modal-container-overflow);
  z-index: var(--z-index-2x);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.panelWrap {
  display: flex;
  align-items: var(--modal-panel-wrap-align-items);
  justify-content: center;
  padding: var(--modal-panel-wrap-padding);
  min-height: var(--modal-panel-wrap-min-height);
  height: var(--modal-panel-wrap-height);
}

.panel {
  background: var(--modal-panel-background);
  margin: var(--modal-margin, 0 auto);
  position: var(--modal-panel-position);
  inset: 0;
  text-align: left;
  color: var(--color-gray-500);
  padding: var(--modal-padding);
  overflow: var(--modal-panel-overflow);
  max-height: var(--modal-max-height);
  width: var(--modal-width, 100%);
  min-width: var(--modal-min-width, auto);
  max-width: var(--modal-max-width, none);
  height: var(--modal-height, 100%);
  display: flex;
  flex-direction: column;
  align-items: var(--modal-panel-align-items);
  gap: var(--modal-panel-gap);
  box-shadow: var(--modal-panel-shadow);
  border-radius: var(--modal-panel-border-radius);
  scroll-behavior: smooth;
}

.closeButton {
  --anchor-padding: var(--modal-close-button-padding);
  --anchor-display: inline-block;
  --button-padding: var(--modal-close-button-padding);
  --button-height: 100%;
  --button-font-size: var(--modal-close-button-font-size);
  --button-text-color-base: var(--modal-close-button-color);
  --button-text-color-hover: var(--modal-close-button-color-hover);
  position: var(--modal-close-button-position);
  top: var(--modal-close-button-top);
  right: var(--modal-close-button-right);
  cursor: pointer;
  background: var(--modal-close-button-background, transparent);
  border-radius: var(--modal-close-button-border-radius);
  z-index: var(--z-index-2x);
}

.modalGrowEnter {
  transition: all 150ms;
}

.modalGrowEnterFrom {
  transform: scale(0.95) translate3d(0, 10%, 0);
  opacity: 0;
}

.modalGrowEnterTo {
  transform: scale(1) translate3d(0, 0, 0);
  opacity: 1;
  overflow-x: hidden;
}

.modalBottomSlideEnter {
  transition: all 300ms;
}

.modalBottomSlideEnterFrom {
  transform: translateY(100%);
}

.modalBottomSlideEnterTo {
  transform: translateY(0);
}

.modalShrinkFadeLeave,
.modalFadeLeave {
  transition: all 100ms;
}

.modalShrinkFadeLeaveFrom {
  @media (--screen-md) {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}

.modalShrinkFadeLeaveTo {
  @media (--screen-md) {
    transform: scale(0.95) translate3d(0, 10%, 0);
    opacity: 0;
  }
}

.modalFadeLeaveFrom {
  opacity: 1;
}

.modalFadeLeaveTo {
  opacity: 0;
}
